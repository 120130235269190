export const AUTH  = "AUTH";
export const LOGOUT = "LOGOUT";
export const UPDATE = "UPDATE";
export const ERROR = "ERROR";
export const CLEARERROR = "CLEARERROR"

export const CREATE_NOTE = "CREATE_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";

export const UPDATE_FILE = "UPDATEFILE"

export const CREATE_TASK = "CREATE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";

export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING"